<template>
  <WeContainer header="true" v-bind:actions="containerActions" v-if="ready">
    <WeTable
      v-bind:draggable="true"
      v-bind:index="false"
      v-bind:data.sync="shop.list"
      v-bind:columns="columns"
      v-bind:actions="tableActions"
      v-on:on-action="onAction"
      v-on:on-switch="onSwitch"
      v-on:order-changed="onOrderChange"
    ></WeTable>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "List",
  data() {
    return {
      ready: false,
      tableActions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary ml-2",
          action: "edit",
        },
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      columns: [
        { name: "image", icon: "fas fa-camera", type: "image", width: "5%" },
        { name: "name", th: "Mağaza Adı", type: "string" },
        { name: "is_active", th: "Durum", type: "boolean", width: "10%" },
      ],
    };
  },
  methods: {
    ...mapActions("shop", ["getList", "delete", "isActive", "orderUpdate"]),
    getAll() {
      this.ready = false;
      this.getList({
        onFinish: (result) => {
          this.ready = true;
        },
      });
    },
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      } else if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      this.$router.replace("/shops/detail/" + row.id);
    },
    onSwitch(data) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        this.isActive({
          form: data,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.$toast.success(this.$t("statusUpdated"));
            } else {
              this.$toast.error(this.$t("statusCouldNotUpdate"));
            }
          },
          onError: (error) => {
            this.$toast.error(this.$t("statusCouldNotUpdate"));
          },
        });
      }
    },
    onDelete(row) {
      let message = `${row.name} adlı mağazayı silmek istediğinize emin misiniz ?`;

      this.$swal({
        title: message,
        html: `Bu işlemi <b class="text-danger">Çöp Kutusu</b> bölümünden geri alabilirsiniz.`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("d");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.delete({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Mağaza Başarıyla Silindi");
                } else {
                  this.$swal({
                    title: "Mağaza Silinemedi",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    onOrderChange(data) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        this.orderUpdate({
          form: data,
          onSuccess: (result) => {
            this.$toast.success(this.$t("orderUpdated"));
            this.shop.list = this.shop.list.sort(
              (a, b) => b.order_no - a.order_no
            );
          },
          onError: () => {
            this.$toast.error(this.$t("orderCouldNotUpdate"));
          },
        });
      }
    },
    checkPermission(code) {
      return permission.check("shop", code);
    },
  },
  computed: {
    ...mapState(["shop", "shared"]),
    containerActions() {
      return [
        {
          text: this.$t("newShop"),
          path: "/shops/new",
        },
        {
          text: this.$t("trash"),
          class: "btn btn-outline-danger float-md-right",
          icon: "fas fa-trash",
          path: "shops/trash",
        },
      ];
    },
  },
  mounted() {
    this.getAll();
  },
};
</script>